@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  min-height: 100%;
  font-size: 14px;
  line-height: 24px;
  overflow-y: scroll;
  display: flex;
  font-family: "Open Sans", sans-serif;
  background: #f0f2f5;
  flex-direction: column;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  color: #5468ff;
}

em {
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
}

.search-container {
  position: relative;
}

.search-container form input {
  max-width: 584px !important;
  height: 48px;
  border-radius: 23px;
  border: solid 1px #979797;
  background-color: #fafafa;
  padding-left: 40px;
}

#searchbox {
  flex-grow: 0;
  margin-bottom: 8px;
}

#stats,
#searchbox {
  flex-grow: 0;
}

.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ais-GeoSearch,
.ais-GeoSearch-map {
  height: 100%;
}

.ais-SearchBox-input:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.ais-GeoSearch-control {
  display: none;
}

.ais-SearchBox {
  position: relative;
  height: auto;
  width: 100%;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  top: 0;
  left: inherit;
  margin: 14px 16px;
  cursor: pointer;
}

.ais-SearchBox-submitIcon,
.ais-SearchBox-resetIcon {
  width: 13px;
  height: 13px;
  display: block;
}

.ais-SearchBox-submitIcon {
  fill: #5468ff;
}

.ais-SearchBox-resetIcon {
  fill: #ed5a6a;
}

.ais-SearchBox-submit {
  right: 0;
}

.ais-SearchBox-reset {
  background: none;
  padding: 0;
  border: none;
  right: 30px;
}

.ais-SearchBox-input {
  width: 100%;
  padding: 8px 16px;
  font-weight: normal;
  border: none;
  font: inherit;
  -webkit-appearance: none;
  appearance: none;
}

#stats {
  flex-grow: 0;
  margin-bottom: 16px;
}

.ais-Stats {
  font-size: 12px;
  color: #697782;
  opacity: 1;
}

#hits {
  margin-right: 24px;
  flex-grow: 1;
}

.ais-Hits-list {
  padding: 0;
}

.ais-Hits-list:last-child {
  margin-bottom: 0;
}

.ais-Hits-item {
  border-bottom: 1px solid lightgray;
  text-align: left;
}

.ais-Hits-item .hit-airport-code,
.ais-Hits-item .hit-location,
.ais-Hits-item .hit-name {
  margin: 0;
}

.ais-Hits-item .hit-name {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 8px;
}

.ais-Hits-item .hit-location {
  text-transform: uppercase;
  font-size: 12px;
  color: #999;
}

.ais-Hits-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.ais-Hits-item,
.ais-SearchBox-input {
  height: auto;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
}

.ais-Maps {
  height: 100%;
  width: 100%;
}

footer {
  flex-grow: 0;
  text-align: center;
  font-size: 12px;
  color: #999;
}

#popover-contained {
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
  height: 490px;
  max-width: 532px;
  padding: 40px 40px;
}

#map-popover {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
  color: #333f48;
  line-height: 1.15;
  font-size: 15px;
  font-weight: 400;
  height: 509px;
  max-width: 410px;
  text-align: justify;
  width: 410px;
}

.map-popover__info {
  padding: calc(30px - .5rem) calc(30px - .75rem);
}

.map-popover__address {
  height: 23px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #262626;
}

.map-popover__get-directions {
  color: #0093d7;
  cursor: pointer;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 23px;
  margin-bottom: 12px;
  letter-spacing: 0.75px;
  line-height: normal;
}

.map-popover__need {
  align-items: center;
  color: #262626;
  display: flex;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 23px;
  letter-spacing: 0.75px;
  line-height: normal;
  margin-bottom: 34px;
}

.map-popover__need span {
  margin-left: 10px;
}

.map-popover__host-a-food-drive {
  color: #0093d7;
  cursor: pointer;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 23px;
  letter-spacing: 0.8px;
  line-height: normal;
  text-transform: uppercase;
  width: 205px;
}

.bold-text {
  font-weight: bold;
}

.place__info-eligibility {
  margin-bottom: 20px;
}

.place__info-times {
  margin-bottom: 20px;
}

.place__info-contact {
  margin-bottom: 20px;
}
